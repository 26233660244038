import jwtDecode from 'jwt-decode';
import LOCAL_STORAGE from './storage/local';

function replaceUnderscoresAndDashesWithSpaces(str) {
  return str?.replace?.(/[_-]/g, ' ');
}

function capitalizeFirstLetter(str = '') {
  return str
    .replace(/[^a-zA-Z0-9 ]/g, ' ') // Replace any special character with a space
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lowercase the rest
    .join(' ');
}

// Detects if device is on iOS
const isIos = () => {
  const browserInfo = navigator.userAgent.toLowerCase();
  if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
    return true;
  }
  if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
    return true;
  }
  return false;
};

const getDateDifference = (startDate) => {
  const startDateWithZ = `${startDate}Z`;
  const endDateUTC = new Date().toUTCString();

  const diffMilliseconds = new Date(endDateUTC) - new Date(startDateWithZ);

  let diff;
  let unit;
  let value;

  if (diffMilliseconds < 1000) {
    diff = diffMilliseconds;
    unit = 'ms ago'; // milliseconds
    value = `0s ago`;
  } else if (diffMilliseconds < 60000) {
    diff = Math.ceil(diffMilliseconds / 1000);
    unit = 'sec ago'; // seconds
    value = `${diff} ${unit}`;
  } else if (diffMilliseconds < 3600000) {
    diff = Math.ceil(diffMilliseconds / (1000 * 60));
    unit = 'min ago'; // minutes
    value = `${diff} ${unit}`;
  } else if (diffMilliseconds < 86400000) {
    diff = Math.ceil(diffMilliseconds / (1000 * 60 * 60));
    unit = 'hrs ago'; // hours
    value = `${diff} ${unit}`;
  } else {
    diff = Math.ceil(diffMilliseconds / (1000 * 60 * 60 * 24));
    unit = 'days ago';
    value = `${diff} ${unit}`;
  }

  return value;
};

// Utility function to handle token decoding and local storage
const handleToken = async (token) => {
  const decodedToken = jwtDecode(token);
  LOCAL_STORAGE.encode('access_token', token);
  LOCAL_STORAGE.encode('user_type', decodedToken?.type);
  LOCAL_STORAGE.encode('user', decodedToken);
  return decodedToken;
};

// clear local storage on logout
const clearLocalStorage = () => {
  LOCAL_STORAGE.remove('access_token');
  LOCAL_STORAGE.remove('user_type');
  LOCAL_STORAGE.remove('user');
};

// Utility function to redirect based on conditions
// const handleRedirect = (decodedToken) => {
//   if (!!decodedToken && decodedToken?.type === USER_TYPES.ADMIN) {
//     window.location.reload(`${window.location.origin}/admin/dashboard`);
//   } else if (!!decodedToken && decodedToken?.type === USER_TYPES.MERCHANT) {
//     window.location.reload(`${window.location.origin}/`);
//   } else {
//     return;
//   }
// };

function formatDate(dateString) {
  const date = new Date(dateString);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const day = date.getUTCDate(); // Extract the day
  const month = months[date.getUTCMonth()]; // Get month name
  const year = date.getUTCFullYear(); // Extract the year

  return `${month?.slice?.(0, 3)} ${day}, ${year}`;
}
function formatOrderStatus(orderStatus) {
  return orderStatus
    .toLowerCase() // Convert to lowercase
    .replace(/_/g, ' ') // Replace underscores with spaces
    .split(' ') // Split the string by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back together
}

const getBackgroundColor = (status) => {
  switch (status) {
    case 'approved':
      return 'success';
    case 'approved_by_sales':
      return 'success';
    case 'disapproved':
      return 'error';
    case 'rejected':
      return 'error';
    case 'pending':
      return 'warning';
    default:
      return 'default';
  }
};

const handleBackgroundColor = (status) => {
  switch (status) {
    case 'approved':
    case 'approved_by_sales':
      return '#BAEDBD';
    case 'disapproved':
      return '#A13D35';
    case 'pending':
      return '#D3B367';
    default:
      return '#B1E3FF';
  }
};

const handleFontColorChange = (status) => {
  const backgroundColor = handleBackgroundColor(status);
  switch (backgroundColor) {
    case '#BAEDBD':
    case 'lightgrey':
    case '#B1E3FF':
      return '#000000';
    case '#A13D35':
      return '#FFFFFF';
    default:
      return '#000000';
  }
};

export {
  formatDate,
  capitalizeFirstLetter,
  getDateDifference,
  isIos,
  replaceUnderscoresAndDashesWithSpaces,
  handleToken,
  //   handleRedirect,
  formatOrderStatus,
  clearLocalStorage,
  getBackgroundColor,
  handleFontColorChange,
  handleBackgroundColor
};
