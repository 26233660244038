import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: 'https://flaship-be.atsol.io',
  timeoutErrorMessage: 'Server failed to response in the given time.',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    console.log('Interceptor request config', config);
    return config;
  },
  (error) => {
    console.error('Interceptor request', error);
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log('Interceptor response', response);
    return response;
  },
  (error) => {
    console.error('Interceptor request', error);
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
