// hooks
import useLocalStorage from '../hooks/useLocalStorage';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const [user] = useLocalStorage('user');

  return (
    <MAvatar
      src={user?.profile_picture}
      alt={user?.business_name}
      color={user?.profile_picture ? 'default' : createAvatar(user?.business_name).color}
      {...other}
    >
      {createAvatar(user?.business_name).name}
    </MAvatar>
  );
}
