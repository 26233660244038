const LOCAL = {
  encode: ($key, $data) => {
    localStorage.setItem($key, JSON.stringify($data));
  },
  decode: ($key) => JSON.parse(localStorage.getItem($key)) || null,
  remove: ($key) => {
    localStorage.removeItem($key);
  }
};

export default LOCAL;
