import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { USER_TYPES } from '../utils/constants';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const [userData] = useLocalStorage('user');
  const [userType] = useLocalStorage('user_type');
  const [accessToken] = useLocalStorage('access_token');

  if (accessToken && userData && userType === USER_TYPES.ADMIN) {
    return <Navigate to={PATH_DASHBOARD.general.app} />;
  }

  if (accessToken && userData && userType === USER_TYPES.MERCHANT) {
    return <Navigate to={PATH_DASHBOARD.general.app} />;
  }

  return <>{children}</>;
}
