import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Stack, Button, Drawer, Tooltip, Typography, CardActionArea } from '@mui/material';
// hooks
import useLocalStorage from '../../hooks/useLocalStorage';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import { ICONS } from './SidebarConfig';
import { DocIllustration } from '../../assets';
import { ADMIN_ROLES, USER_TYPES } from '../../utils/constants';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [loggedInUserType] = useLocalStorage('user_type');
  const [loggedInUserData] = useLocalStorage('user');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const isAdmin = loggedInUserType === USER_TYPES.ADMIN;
  const isMerchant = loggedInUserType === USER_TYPES.MERCHANT;

  let sidebarConfig = [];

  if (isAdmin) {
    sidebarConfig = [
      {
        items: [
          {
            title: 'Dashboard',
            path: PATH_DASHBOARD.general.app,
            icon: ICONS.dashboard
          }
        ]
      },
      {
        items: [
          {
            title: 'Merchants',
            path: PATH_DASHBOARD.user.list,
            icon: ICONS.user
            // children: [
            //   { title: 'All Merchants', path: PATH_DASHBOARD.user.list },
            //   { title: 'Approvals', path: PATH_DASHBOARD.user.approvals }
            // ]
          }
        ]
      },
      ...(loggedInUserData?.is_superuser || loggedInUserData?.roles?.includes(ADMIN_ROLES.SALES_ADMIN)
        ? [
            {
              items: [
                {
                  title: 'Orders',
                  path: PATH_DASHBOARD.order.orderList,
                  icon: ICONS.analytics
                }
              ]
            }
          ]
        : []),
      ...(loggedInUserData?.is_superuser || loggedInUserData?.roles?.includes(ADMIN_ROLES.SALES_ADMIN)
        ? [
            {
              items: [
                {
                  title: 'Return/ Claim Orders',
                  path: PATH_DASHBOARD.order.returnManagement,
                  icon: ICONS.booking
                }
              ]
            }
          ]
        : []),
      ...(loggedInUserData?.is_superuser || loggedInUserData?.roles?.includes(ADMIN_ROLES.FINANCE_ADMIN)
        ? [
            {
              items: [
                {
                  title: 'Payments',
                  path: PATH_DASHBOARD.payment.root,
                  icon: ICONS.banking,
                  children: [
                    { title: 'Process', path: PATH_DASHBOARD.payment.orderPaymentsProcess },
                    { title: 'Transactions', path: PATH_DASHBOARD.payment.transactions }
                    // { title: 'Ledger', path: PATH_DASHBOARD.payment.ledger }
                  ]
                }
              ]
            }
          ]
        : [])
    ];
  } else if (isMerchant) {
    sidebarConfig = [
      {
        items: [
          {
            title: 'Dashboard',
            path: PATH_DASHBOARD.general.app,
            icon: ICONS.dashboard
          }
        ]
      },
      {
        items: [
          {
            title: 'Orders',
            path: PATH_DASHBOARD.order.root,
            icon: ICONS.banking,
            children: [
              { title: 'Order List', path: PATH_DASHBOARD.order.orderList },
              { title: 'Load Sheet', path: PATH_DASHBOARD.order.loadSheet },
              {
                title: 'Order Returns',
                path: PATH_DASHBOARD.order.returnManagement
              },
              {
                title: 'Returns Received',
                path: PATH_DASHBOARD.order.returnReceived
              }
            ]
          }
        ]
      },
      {
        items: [
          {
            title: 'Payments',
            path: PATH_DASHBOARD.payment.root,
            icon: ICONS.booking,
            children: [
              { title: 'Transactions', path: PATH_DASHBOARD.payment.transactions },
              { title: 'Order Payments', path: PATH_DASHBOARD.payment.orderPayments },
              { title: 'Ledger', path: PATH_DASHBOARD.payment.ledger }
            ]
          }
        ]
      }
      // {
      //   items: [
      //     {
      //       title: 'Return/ Claim Orders',
      //       path: PATH_DASHBOARD.order.returnManagement,
      //       icon: ICONS.calendar
      //     }
      //   ]
      // }
    ];
  }

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/dashboard/app" sx={{ display: 'inline-flex' }}>
            <Logo />
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>
        </Stack>

        {isCollapse ? (
          <>{/* <MyAvatar sx={{ mx: 'auto', mb: 2 }} /> */}</>
        ) : (
          <>
            {/* <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.account}>
              <AccountStyle>
                <MyAvatar />
                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    {user?.displayName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {user?.role}
                  </Typography>
                </Box>
              </AccountStyle>
            </Link> */}
          </>
        )}
      </Stack>

      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
